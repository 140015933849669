/* You can add global styles to this file, and also import other style files */
body {
  background-image: url('assets/polkadots_bg.gif');
	font-size: .80em;
	font-family: "Helvetica Neue", "Lucida Grande", "Segoe UI", Arial, Helvetica, Verdana, sans-serif;
	margin: 0;
	padding: 0;
}

#frontpage
{
	margin: auto;
	background-image: url('assets/content_middle_bg.gif');
	width: 1092px;
	height: 100%;
}

#main
{
	margin-top: 0;
	margin-left: 46px;
	margin-right: 46px;
	height: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}

#main a {
  color: black;
}

#copyright-frontpage {
	position: fixed;
	text-align: center;
	bottom: 10px;
	width: 100%;
}

#header-frontpage
{
	background-image: url('assets/header_bg_frontpage.png');
	background-repeat: no-repeat;
	min-height: 339px;
}

